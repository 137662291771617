window.jQuery = require('jquery')
require('./vendor/parallax')

import WebFont from 'webfontloader'
import inView from 'in-view'
import siteHeader from '../sections/site-header/site-header'
import intro from '../sections/intro/intro'
import parallax from '../sections/parallax/parallax'

global.$ = jQuery

WebFont.load({
    google: {
        families: [
            'Roboto:300,400,700',
            'Roboto Slab:300,400,700'
        ]
    }
})

inView.offset(200)
inView('.js-inview').on('enter', el => {
    el.classList.add('is-inview')
})

// Init components
siteHeader()
intro()
parallax()
