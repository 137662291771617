import throttle from '../../scripts/utils/throttle'

const headerStyle = () => {
    const offset = $(document).scrollTop()

    if (offset > 80) {
        $('body').addClass('has-fixed')
    } else {
        $('body').removeClass('has-fixed')
    }
}

const siteHeader = () => {
    $(document).on('scroll', throttle(headerStyle, 10))

    $('.js-showOverlay').on('click', function (e) {
        e.preventDefault()

        $(this).toggleClass('is-active')
        $('body').toggleClass('has-overlay')
    })

    $('.js-hideOverlay').on('click', function (e) {
        e.preventDefault()

        $('.js-showOverlay').removeClass('is-active')
        $('body').removeClass('has-overlay')
    })
}

export default siteHeader
