import Flickity from 'flickity'
import Rellax from 'rellax'

const intro = () => {
    if ($('.js-introSlider').length) {
        const slider = document.querySelector('.js-introSlider')
        const flkty = new Flickity(slider, {
            wrapAround: true,
            pageDots: false
        })
        flkty.select(0)
    }

    if ($('.js-rellax').length) {
        const rellax = new Rellax('.js-rellax')
    }
}

export default intro
